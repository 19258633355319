import { HHDialog, HHDialogTitle } from '@hinge-health/react-component-library';
import { Close } from '@mui/icons-material';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { useCurrentStoredAdminHook } from '../../../hooks/use-current-stored-admin';
import { currentUserAtom } from '../../../modules/member-360/state/atoms';
import { sendFeedback } from '../../../utils/feedback-dialog-utils';
import { FeedbackSubmissionForm } from './feedback-dialog-form';
import { layoutStyles, sxStyles } from './feedback-dialog-styles';
interface FeedbackDialogContentProps {
  isOpen: boolean;
  toggleFunction: (value: boolean) => void;
}
interface FeedbackData {
  name: string;
  email: string;
  uuid: string;
}
interface FileUploadMessage {
  status: boolean;
  message: string;
}
export interface FeedbackMessage {
  feedbackType: string;
  title: string;
  description: string;
  memberUuids?: string;
}
interface FromError {
  titleError: string;
  descriptionError: string;
  fileUploadError: string;
}
export type FeedbackType = 'BUG' | 'REQUEST';
export const FeedbackDialog = ({
  toggleFunction,
  isOpen
}: FeedbackDialogContentProps): JSX.Element => {
  const [feedbackFiles, setFeedbackFiles] = useState<FileList | null>(null);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState<FromError>({
    titleError: '',
    descriptionError: '',
    fileUploadError: ''
  });
  const [fileUploadMessage, setFileUploadMessage] = useState<FileUploadMessage>({
    status: true,
    message: ''
  });
  const [jiraTicketNumber, setJiraTicketNumber] = useState<string>('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [feedbackSubmittedSuccessFully, setFeedbackSubmittedSuccessFully] = useState<boolean>(true);
  const [feedbackLoader, setFeedbackLoader] = useState<boolean>(false);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const currentUser = useAtomValue(currentUserAtom);
  const [feedbackData] = useState<FeedbackData>({
    name: `${currentStoredAdmin?.firstName} ${currentStoredAdmin?.lastName}`,
    email: currentStoredAdmin?.email || '',
    uuid: currentStoredAdmin?.uuid || ''
  });
  const bugFeatureChip = {
    bug: USER_FEEDBACK.BUG_STRING,
    productFeature: USER_FEEDBACK.REQUEST_STRING
  };
  const handleToggleBugFeatureChip = (key: 'bug' | 'productFeature'): void => {
    setFeedbackMessage({
      ...feedbackMessage,
      feedbackType: bugFeatureChip[key]
    });
  };
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>({
    feedbackType: bugFeatureChip.bug,
    title: '',
    description: '',
    memberUuids: currentUser?.uuid || ''
  });
  const [initFeedbackMessage] = useState<FeedbackMessage>(feedbackMessage);

  // Function to trigger file input click
  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the file input
    }
  };
  const handleDeleteItem = (index: number): void => {
    if (feedbackFiles) {
      const files = Array.from(((feedbackFiles as unknown) as File[]));
      files.splice(index, 1);
      const fileList = ((files as unknown) as FileList);
      setFeedbackFiles(fileList);
    }
  };
  const handleSubmitFeedback = async (): Promise<void> => {
    if (!feedbackMessage.title) {
      setFeedbackErrorMessage({
        ...feedbackErrorMessage,
        titleError: USER_FEEDBACK.ERROR_TEXT_VALUE
      });
      return;
    } else {
      setFeedbackErrorMessage({
        ...feedbackErrorMessage,
        titleError: ''
      });
    }
    if (!feedbackMessage.description) {
      setFeedbackErrorMessage({
        ...feedbackErrorMessage,
        descriptionError: USER_FEEDBACK.ERROR_TEXT_VALUE
      });
      return;
    } else {
      setFeedbackErrorMessage({
        ...feedbackErrorMessage,
        descriptionError: ''
      });
    }
    // Concat the description
    let descriptionTextPrefix = '';
    const descriptionTextSuffix = feedbackMessage.description;
    if (feedbackMessage.feedbackType.toUpperCase() === USER_FEEDBACK.BUG_STRING) {
      descriptionTextPrefix = USER_FEEDBACK.DIALOG_TEXTS.BUG.DESCRIPTION_PLACEHOLDER;
    } else {
      descriptionTextPrefix = USER_FEEDBACK.DIALOG_TEXTS.REQUEST.DESCRIPTION_PLACEHOLDER;
    }
    const descriptionText = `${descriptionTextPrefix} \n${descriptionTextSuffix}`;
    let memberUUIDText = '';
    if (feedbackMessage.feedbackType.toUpperCase() === USER_FEEDBACK.BUG_STRING && feedbackMessage.memberUuids) {
      memberUUIDText = `${USER_FEEDBACK.MEMBER_ID_PREFIX}${feedbackMessage.memberUuids}`;
    }
    const message = `${descriptionText} \n${memberUUIDText}`;
    setFeedbackLoader(true);
    const sendFeedbackCall = (await sendFeedback({
      userData: feedbackData,
      message,
      type: feedbackMessage.feedbackType,
      feedbackFiles,
      title: feedbackMessage.title
    }) as {
      key: string;
      status: boolean;
      fileUpload: FileUploadMessage;
    });
    if (sendFeedbackCall && sendFeedbackCall.status) {
      setFeedbackErrorMessage({
        titleError: '',
        descriptionError: '',
        fileUploadError: ''
      });
      setFeedbackSubmitted(true);
      setFeedbackLoader(false);
      localStorage.removeItem(USER_FEEDBACK.FEEDBACK_PERSIST_KEY);
      // set the initial/blank feedback messages after submission
      setFeedbackMessage(initFeedbackMessage);
      setJiraTicketNumber(sendFeedbackCall?.key);
      setFeedbackFiles(null);
      setFileUploadMessage(sendFeedbackCall?.fileUpload);
    } else {
      setFeedbackSubmittedSuccessFully(false);
    }
  };
  useEffect(() => {
    const storedValue = localStorage.getItem(USER_FEEDBACK.FEEDBACK_PERSIST_KEY);
    const currentMemberUuid = currentUser?.uuid;
    if (storedValue) {
      // Checking the memberUuids in local storage and updating it with the current user uuid
      const localStorageData = JSON.parse(storedValue);
      let memberUuids = localStorageData.memberUuids;
      if (currentMemberUuid && memberUuids != currentMemberUuid) {
        memberUuids = currentMemberUuid;
      }
      localStorageData['memberUuids'] = memberUuids;
      setFeedbackMessage(localStorageData);
    }
  }, [currentUser]);
  const handleCloseFeedbackDialog = (): void => {
    toggleFunction(!isOpen);
    // Save the unsaved feedback in local storage
    if (!feedbackSubmitted) {
      localStorage.setItem(USER_FEEDBACK.FEEDBACK_PERSIST_KEY, JSON.stringify(feedbackMessage));
    }
  };

  // Function to handle the file upload
  const handleFeedbackFilesUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length <= USER_FEEDBACK.MAX_NUMBER_OF_FILES_UPLOAD) {
      setFeedbackFiles(prevFiles => {
        const newFiles = e.target.files;
        if (prevFiles && newFiles && prevFiles.length + newFiles.length <= USER_FEEDBACK.MAX_NUMBER_OF_FILES_UPLOAD) {
          const fileList_0 = ((Array.from(((prevFiles as unknown) as File[])).concat(Array.from(((newFiles as unknown) as File[]))) as unknown) as FileList);
          return fileList_0;
        }
        return newFiles;
      });
    } else {
      setFeedbackErrorMessage({
        ...feedbackErrorMessage,
        fileUploadError: USER_FEEDBACK.FILE_LIMIT_ERROR
      });
    }
  };
  return <HHDialog open={isOpen}
  // closeAfterTransition
  sx={{
    padding: '5px'
  }} PaperProps={{
    sx: sxStyles.hhDialogPaperProps
  }}>
      <HHDialogTitle>
        {!feedbackSubmitted && USER_FEEDBACK.DIALOG_TEXTS.FROM_TITLE}
        <Close data-testid={USER_FEEDBACK.TEST_IDS.CLOSE_BUTTON} sx={sxStyles.closeButton} onClick={(): void => handleCloseFeedbackDialog()} />{' '}
      </HHDialogTitle>
      <FeedbackSubmissionForm feedbackSubmitted={feedbackSubmitted} setFeedbackSubmitted={setFeedbackSubmitted} bugFeatureChip={bugFeatureChip} feedbackMessage={feedbackMessage} handleToggleBugFeatureChip={handleToggleBugFeatureChip} fileInputRef={fileInputRef} handleFeedbackFilesUpload={handleFeedbackFilesUpload} handleButtonClick={handleButtonClick} handleSubmitFeedback={handleSubmitFeedback} setFeedbackMessage={setFeedbackMessage} feedbackLoader={feedbackLoader} jiraTicketNumber={jiraTicketNumber} feedbackFiles={feedbackFiles} handleDeleteItem={handleDeleteItem} feedbackErrorMessage={feedbackErrorMessage} feedbackSubmittedSuccessFully={feedbackSubmittedSuccessFully} fileUploadMessage={fileUploadMessage} sxStyles={sxStyles} layoutStyles={layoutStyles} />
    </HHDialog>;
};