/* eslint-disable @typescript-eslint/naming-convention */

import { CoachDashboardFilters } from './../types/coach';
import { COACH_CALL_TAB_POST_CALL_TASKS, COACH_CALL_TAB_UPCOMING_COACH_CALLS, COACH_VIDEO_VISIT_TAB_NAMES, DEFAULT_COACH_DASHBOARD_TAB_NAME, DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER, LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from './strings/coach-dashboard-default';
export type FilterType<T> = {
  filterLabel: T;
  filterPageNumber: number;
  filterTabName: string;
};
export type DashboardFilterType<T extends string> = { [K in T]: FilterType<T> };
export type CoachDashboardFilterType = DashboardFilterType<CoachDashboardFilters>;
export const CoachDashboardFilter: CoachDashboardFilterType = {
  All: {
    filterLabel: CoachDashboardFilters.All,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.UnreadMessages]: {
    filterLabel: CoachDashboardFilters.UnreadMessages,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  Completed: {
    filterLabel: CoachDashboardFilters.Completed,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.MemberHealthLog]: {
    filterLabel: CoachDashboardFilters.MemberHealthLog,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.ReEngager]: {
    filterLabel: CoachDashboardFilters.ReEngager,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.MemberHealthLogNoResponse]: {
    filterLabel: CoachDashboardFilters.MemberHealthLog,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.UnreadMessagesNoResponse]: {
    filterLabel: CoachDashboardFilters.UnreadMessages,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.ActionPlanCheckIn]: {
    filterLabel: CoachDashboardFilters.ActionPlanCheckIn,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.FirstWeekCheckIn]: {
    filterLabel: CoachDashboardFilters.FirstWeekCheckIn,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.CoachCalls]: {
    filterLabel: CoachDashboardFilters.CoachCallsUpcomingCoachCalls,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: COACH_CALL_TAB_UPCOMING_COACH_CALLS
  },
  [CoachDashboardFilters.CoachCallsPostCallTasks]: {
    filterLabel: CoachDashboardFilters.CoachCallsPostCallTasks,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: COACH_CALL_TAB_POST_CALL_TASKS
  },
  [CoachDashboardFilters.CoachCallsUpcomingCoachCalls]: {
    filterLabel: CoachDashboardFilters.CoachCallsUpcomingCoachCalls,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: COACH_CALL_TAB_UPCOMING_COACH_CALLS
  },
  [CoachDashboardFilters.EnsoWeek1]: {
    filterLabel: CoachDashboardFilters.EnsoWeek1,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.CoachEscalation]: {
    filterLabel: CoachDashboardFilters.CoachEscalation,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.WeeklyEnsoReview]: {
    filterLabel: CoachDashboardFilters.WeeklyEnsoReview,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.OutreachNeeded]: {
    filterLabel: CoachDashboardFilters.OutreachNeeded,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.AlertReminder]: {
    filterLabel: CoachDashboardFilters.AlertReminder,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.MaternityMilestones]: {
    filterLabel: CoachDashboardFilters.MaternityMilestones,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.PregnancyStatusChange]: {
    filterLabel: CoachDashboardFilters.PregnancyStatusChange,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.ReviewAndRecalibrate]: {
    filterLabel: CoachDashboardFilters.ReviewAndRecalibrate,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.PainUptick]: {
    filterLabel: CoachDashboardFilters.PainUptick,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.BalanceAssessment]: {
    filterLabel: CoachDashboardFilters.BalanceAssessment,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.FirstWeekReview]: {
    filterLabel: CoachDashboardFilters.FirstWeekReview,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.CoachWeeklyGoalExpiry]: {
    filterLabel: CoachDashboardFilters.CoachWeeklyGoalExpiry,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal]: {
    filterLabel: CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_COACH_DASHBOARD_TAB_NAME
  },
  [CoachDashboardFilters.CoachVideoVisit]: {
    filterLabel: CoachDashboardFilters.CoachVideoVisit,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: COACH_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS
  },
  [CoachDashboardFilters.CoachVideoVisitIncomplete]: {
    filterLabel: CoachDashboardFilters.CoachVideoVisitIncomplete,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: COACH_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS
  },
  [CoachDashboardFilters.CoachVideoVisitUpcoming]: {
    filterLabel: CoachDashboardFilters.CoachVideoVisitUpcoming,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: COACH_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS
  }
};