import { useLazyQuery } from '@apollo/client';
import { HHTableCell, HHTableRow } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SESSION_ID } from '../../../../../constants/strings/member';
import { acuteLayoutUrl, memberLayoutUrl, portalSidebarUrl } from '../../../../../constants/strings/workflow-urls';
import { acuteSidebarWorkflows, IamMessageSource, PtWorkflowTypes, threePanelLayoutWorkflows } from '../../../../../custom-types';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { WorkflowSelectedFromDashboardProperties } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { SPLIT_TREATMENTS as PT_SPLIT_TREATEMENTS } from '../../../../acute-physical-therapy/constants/splits';
import { AcuteGroupType } from '../../../../acute-physical-therapy/custom-types';
import { isVideoVisitsWorkflowType } from '../../../../acute-physical-therapy/utils/video-visits';
import { GET_USER_INFO } from '../../../../member-360/queries/user.graphql';
import { ON, SPLIT_TREATMENTS } from '../../../constants/splits';
import { LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { useShellContext } from '../../../contexts/shell.context';
import { completeWorkflowFilters, DashboardType, UnreadFilterType } from '../../../custom-types';
import { changePT, listOfIncompletedWorkflowsObject, listOfWorkflowsObject, listToMarkWorkflowComplete, listToMarkWorkflowPending, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters } from '../../../jotai/atoms';
import { WorkflowPayload } from '../../../types';
import { CoachWorkflowTypes } from '../../../types/coach';
import { ptCarePlanFilters, PtDashboardFilters } from '../../../types/pt';
import { getCompleteWorkflowFilters } from '../../../utils/get-complete-workflow-filters';
import { getPriorityString } from '../../../utils/get-workflow-priority';
import { WorkflowAssignCell } from './table-row-cells/workflow-assign';
import { WorkflowBulkMark } from './table-row-cells/workflow-bulk-mark';
import { WorkflowDateCell } from './table-row-cells/workflow-created-on';
import { WorkflowDueCell } from './table-row-cells/workflow-due';
import { WorkflowMarkCell } from './table-row-cells/workflow-mark';
import { WorkflowMemberCell } from './table-row-cells/workflow-member';
import { WorkflowMemberStateCell } from './table-row-cells/workflow-member-state';
import { WorkflowTypeCell } from './table-row-cells/workflow-type';
import WorkflowUnreadTypeCell from './table-row-cells/workflow-unread-type-cell';
export type CoachWorkflowsTableRowProps = {
  workflow: WorkflowPayload;
};
export const WorkflowsTableRow = ({
  workflow
}: CoachWorkflowsTableRowProps): JSX.Element => {
  const mixpanelTrack = useGenericMixpanelTrack([]);
  const [selectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const {
    adminProfile
  } = useShellContext();
  const navigate = useNavigate();
  const splitClient = useClient();
  const threePanelLayoutTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.THREE_PANEL_LAYOUT, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const videoVisitDeprecationTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.CTT_DEPRECATION_VIDEO_VISIT, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const workflowName = workflow?.customFields?.source === IamMessageSource.HLN ? UnreadFilterType.HealthLog : UnreadFilterType.Messages;
  const ptPooledDashboardTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_DASHBOARD, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const ptPooledReassignementTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_REASSIGNEMNT, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const modularIamUnreadMessageTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.MODULAR_IAM_UNREAD_MESSAGE, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const [displayCompleteIcon, setDisplayCompleteIcon] = useState(false);
  const [displayAssignIcon, setDisplayAssignIcon] = useState(false);
  const navigateToThreePanelMemberView = threePanelLayoutTreatment && threePanelLayoutWorkflows.includes((workflow.type as CoachWorkflowTypes));
  const [, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [displayEncounterDate, setDisplayEncounterDate] = useState(workflow?.createdAt);
  const isCompleteWorkflowFilter = getCompleteWorkflowFilters([selectedDashboardFilter.filterLabel, selectedPtDashboardFilter.filterLabel, selectedOsDashboardFilter.filterLabel]);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [, setListOfSelectedWorkflows] = useAtom(listOfWorkflowsObject);
  const [, setListOfUnselectedWorkflows] = useAtom(listOfIncompletedWorkflowsObject);
  const allWorkflowFlag = useAtomValue(selectAllWorkflowAtom);
  const allPtWorkflowFlag = useAtomValue(selectAllPTWorkflowAtom);
  const [isChangePT] = useAtom(changePT);
  const [userInfoPrefetched, setUserInfoPrefetched] = useState(false);
  const [getUserInfo] = useLazyQuery(GET_USER_INFO);
  const priorityString = (getPriorityString((workflow?.priority as number)) as string);
  const navigateToMemberView: () => void = useCallback(() => {
    const sessionId = localStorage.getItem(SESSION_ID) || null;
    mixpanelTrack<WorkflowSelectedFromDashboardProperties>(MIXPANEL_EVENTS.WORKFLOW_SELECTED_FROM_DASHBOARD, {
      selectedWorkflowID: workflow.id,
      selectedWorkflowName: workflow.type,
      selectedWorkflowState: workflow.state,
      selectedWorkflowCreatedAt: workflow.createdAt,
      selectedWorkflowMemberUuid: (workflow.patient?.uuid as string),
      selectedWorkflowPriority: priorityString,
      selectedWorkflowTabName: selectedDashboardFilter.filterTabName,
      timeStamp: Date.now().toString(),
      sessionId
    });
    if (acuteSidebarWorkflows.includes((workflow?.type as PtWorkflowTypes)) && (!isVideoVisitsWorkflowType(workflow) || !videoVisitDeprecationTreatment)) {
      navigate(acuteLayoutUrl(workflow.id, workflow.type));
      return;
    }
    let vvGroupType = ('' as AcuteGroupType);
    if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.VideoVisitUpcoming) {
      vvGroupType = AcuteGroupType.Upcoming;
    } else if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.VideoVisitIncomplete) {
      vvGroupType = AcuteGroupType.Incomplete;
    }
    if (isVideoVisitsWorkflowType(workflow)) {
      localStorage.setItem('showMemberSidebar', 'true');
    }
    navigateToThreePanelMemberView ? navigate(memberLayoutUrl(workflow.id, workflow.type, workflow?.patient?.id)) : navigate(portalSidebarUrl(workflow.id, workflow.type, vvGroupType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, navigateToThreePanelMemberView, workflow.id, workflow?.patient?.id, workflow.type]);
  const carePlanWorkflow = ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel);
  const displayMarkCell = selectedDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
  const displayOsMarkCell = selectedOsDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
  const displayAssignCell = currentDashboard === DashboardType.PhysicalTherapist && isChangePT;
  const showHeaderSelectionBox = currentDashboard === DashboardType.PhysicalTherapist;
  const displayPtMarkCell = selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME;
  const displayMemberStateCell = currentDashboard === DashboardType.PhysicalTherapist && ptPooledDashboardTreatment;
  const displayDueCell = !isCompleteWorkflowFilter && !carePlanWorkflow;
  const displayWorkflowUnreadTypeCell = currentDashboard === DashboardType.PhysicalTherapist && modularIamUnreadMessageTreatment && selectedPtDashboardFilter.filterLabel === PtDashboardFilters.UnreadMessage;
  useEffect(() => {
    if (!workflow) return;
    const {
      customFields,
      carePlans
    } = workflow;
    let encounterDate = workflow.createdAt;
    if (customFields?.callDate) {
      encounterDate = customFields?.callDate;
    }
    if (customFields?.scheduledAt) {
      encounterDate = customFields?.scheduledAt;
    }
    if (carePlanWorkflow) {
      if (carePlans?.length && carePlans[0].expires) {
        encounterDate = carePlans[0].expires;
      } else {
        encounterDate = '';
      }
    }
    if (completeWorkflowFilters.includes(selectedPtDashboardFilter.filterLabel) || completeWorkflowFilters.includes(selectedDashboardFilter.filterLabel) || completeWorkflowFilters.includes(selectedOsDashboardFilter.filterLabel)) {
      encounterDate = workflow.lastCompletedAt || '';
    }
    setDisplayEncounterDate(encounterDate);
  }, [carePlanWorkflow, workflow, selectedDashboardFilter, selectedPtDashboardFilter, selectedOsDashboardFilter]);
  const toggleIndividualWorkflows = (checked: boolean, workflowId: number, workflowName_0: UnreadFilterType): void => {
    if (checked) {
      /**Coach Dashboard */
      setListToMarkComplete(prevList => [...prevList, workflowId]);
      setListOfSelectedWorkflows(prevList_0 => [...prevList_0, {
        id: workflowId,
        name: workflowName_0,
        workflowType: workflow.type
      }]);
      setListToMarkPending(prevList_1 => prevList_1.filter(id => id !== workflowId));
      setListOfUnselectedWorkflows(prevList_2 => [...prevList_2, {
        id: workflowId,
        name: workflowName_0,
        workflowType: workflow.type
      }]);
    } else {
      const shouldAdd = allWorkflowFlag || allPtWorkflowFlag;
      setListOfUnselectedWorkflows(prev => shouldAdd ? [...prev, {
        id: workflowId,
        name: workflowName_0,
        workflowType: workflow.type
      }] : []);
      setListToMarkPending(prev_0 => shouldAdd ? [...prev_0, workflowId] : []);
      setListToMarkComplete(prevList_3 => prevList_3.filter(id_0 => id_0 !== workflowId));
      setListOfSelectedWorkflows(prevList_4 => prevList_4.filter(item => item.id !== workflowId));
    }
  };
  const handlePrefetchUserInfo = (): void => {
    if (workflow.patient?.id && !userInfoPrefetched) {
      getUserInfo({
        variables: {
          uuid: workflow.patient.id
        }
      }).then(() => {
        setUserInfoPrefetched(true);
      });
    }
  };
  return <HHTableRow onClick={navigateToMemberView} sx={{
    cursor: 'pointer',
    borderBottom: '1px solid rgb(214, 214, 208)'
  }} hover data-testid={`workflow-row-${workflow.id}`} onMouseOver={(): void => {
    setDisplayCompleteIcon(true);
    setDisplayAssignIcon(true);
    handlePrefetchUserInfo();
  }} onMouseLeave={(): void => {
    setDisplayCompleteIcon(false);
    setDisplayAssignIcon(false);
  }}>
      {(displayMarkCell || displayPtMarkCell || displayOsMarkCell || showHeaderSelectionBox && isChangePT && ptPooledReassignementTreatment) && <HHTableCell style={{
      borderBottom: '1px solid #d6d6d0'
    }}>
          <WorkflowBulkMark workflowId={workflow.id} markType={'body'} onSelect={toggleIndividualWorkflows} workflowName={workflowName} workflowCreatedAt={workflow.createdAt} workflowState={workflow.state} workflowMemberUuid={workflow.patient?.uuid} />
        </HHTableCell>}
      <WorkflowTypeCell workflow={workflow} />
      {displayWorkflowUnreadTypeCell && <WorkflowUnreadTypeCell workflow={workflow} />}
      <WorkflowMemberCell workflow={workflow} />
      {displayMemberStateCell && <WorkflowMemberStateCell workflow={workflow} />}
      <WorkflowDateCell date={displayEncounterDate} />
      {displayDueCell && <WorkflowDueCell workflow={workflow} />}
      {(displayMarkCell || displayPtMarkCell || displayOsMarkCell) && <WorkflowMarkCell workflow={workflow} displayCompleteIcon={displayCompleteIcon} />}
      {displayAssignCell && ptPooledReassignementTreatment && <WorkflowAssignCell workflow={workflow} displayAssignIcon={displayAssignIcon} />}
    </HHTableRow>;
};