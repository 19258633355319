import { useApolloClient } from '@apollo/client';
import { ctpTokens, HHDialog, HHStack, HHSwitch, HHTypography } from '@hinge-health/react-component-library';
import { useMemo, useState } from 'react';
import { SESSION_ID } from '../../../constants/strings/member';
import { useGenericMixpanelTrack } from '../../../hooks/use-generic-mixpanel';
import { MemberRecordProps, MixpanelPropertiesContext } from '../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { PHI_EVENT } from '../../../modules/member-search/constants/strings/member-search';
import { PhiFieldsMaskingKey, PhiFieldsMaskingProgressKey, PhiFieldsMaskingStatusChangedEvent } from '../../../types/types';
import { DisablePHIMaskingDialogContent } from './components/phi-masking-dialog-content/phi-disable-masking-confirmation';
import { PHIMaskingFeedbackDialogContent } from './components/phi-masking-dialog-content/phi-masking-feedback';
import { PHIMaskingProgressDialogContent } from './components/phi-masking-dialog-content/phi-masking-progress';
export const PHI_MASK_SWITCH_BUTTON_TEXT = 'PHI';

// Test IDs
export const PHI_MASK_SWITCH_BUTTON_TEST_ID = 'phi-mask-switch-button';
const styles = {
  switchButtonStyles: {
    '& .MuiSwitch-track': {
      border: '1px solid white',
      boxSizing: 'border-box',
      backgroundColor: ctpTokens.palette.neutral[40],
      opacity: 1
    },
    '&.Mui-checked .MuiSwitch-thumb': {
      backgroundColor: ctpTokens.palette.green[50]
    }
  },
  snackbarStyles: {
    padding: 0,
    '& .MuiSnackbarContent-message': {
      padding: 0,
      backgroundColor: 'transparent',
      width: '100%'
    }
  },
  dialogPaperStyles: {
    width: '490px',
    height: '178px'
  }
};
export type DialogContentType = 'masking-progress' | 'disable-confirmation' | 'masking-feedback';
export interface PhiMaskingDialogProps {
  open: boolean;
  maskingProgress: number;
  dialogContent: DialogContentType;
}
export interface PhiMaskingSwitchButtonProps {
  onChange: (checked: boolean) => void;
}
export const PHIMaskingSwitchButton = ({
  onChange
}: PhiMaskingSwitchButtonProps): JSX.Element => {
  const apolloClient = useApolloClient();
  const [phiMaskingEnabled, setPHIMasking] = useState(localStorage.getItem(PhiFieldsMaskingKey) === 'true');
  const [{
    dialogContent: phiMaskingDialogContent,
    open: phiMaskingDialogOpen,
    maskingProgress
  }, setPhiMaskingDialogProps] = useState<PhiMaskingDialogProps>({
    open: false,
    dialogContent: 'masking-progress',
    maskingProgress: 0
  });
  const handleMaskPHIFields = async (isPHIFieldsMasked: boolean): Promise<void> => {
    setPHIMasking(isPHIFieldsMasked);
    setPhiMaskingDialogProps({
      dialogContent: 'masking-progress',
      maskingProgress: 0,
      open: true
    });
    localStorage.setItem(PhiFieldsMaskingProgressKey, 'masking-progress');
    // Preserve the PHI masking status in the local storage
    localStorage.setItem(PhiFieldsMaskingKey, isPHIFieldsMasked.toString());

    // Call the parent component's onChange callback
    // It's being used to display tooltips for the Switch button
    onChange(isPHIFieldsMasked);

    // Reset Apollo cache to make network requests to fetch the data again
    await apolloClient.resetStore();

    // Dispatch an event to notify the other components about the PHI masking status change
    // This event will be used to rerender the components that need to be updated based on the PHI masking status
    window.dispatchEvent(new Event(PhiFieldsMaskingStatusChangedEvent));
  };
  const sessionId = localStorage.getItem(SESSION_ID);
  const handlePHIMaskingChange = async (): Promise<void> => {
    if (phiMaskingEnabled) {
      localStorage.setItem(PhiFieldsMaskingProgressKey, 'masking-progress');
      setPhiMaskingDialogProps({
        maskingProgress: 0,
        dialogContent: 'disable-confirmation',
        open: true
      });
      genericMixpanelEvents<MemberRecordProps>(PHI_EVENT.PHI_MASK_ENABLE, {
        sessionId
      });
    } else {
      await handleMaskPHIFields(true);
    }
  };
  const handleDisablePHIMask = async (): Promise<void> => {
    await handleMaskPHIFields(false);
    genericMixpanelEvents<MemberRecordProps>(PHI_EVENT.PHI_MASK_DISABLE, {
      sessionId
    });
  };
  const handleClosePHIMaskingDialog = (): void => {
    setPhiMaskingDialogProps(prev => ({
      ...prev,
      open: false
    }));
    window.dispatchEvent(new Event('masking-done'));
    setTimeout(() => {
      localStorage.removeItem(PhiFieldsMaskingProgressKey);
    }, 700);
  };
  const phiMaskingDialogElement = useMemo(() => {
    switch (phiMaskingDialogContent) {
      case 'masking-progress':
        return <PHIMaskingProgressDialogContent phiMaskingEnabled={phiMaskingEnabled} maskingProgress={maskingProgress} setPhiMaskingDialogProps={setPhiMaskingDialogProps} />;
      case 'disable-confirmation':
        return <DisablePHIMaskingDialogContent onConfirm={handleDisablePHIMask} onClose={handleClosePHIMaskingDialog} />;
      case 'masking-feedback':
        return <PHIMaskingFeedbackDialogContent phiMaskingEnabled={phiMaskingEnabled} onClose={handleClosePHIMaskingDialog} />;
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phiMaskingDialogContent, maskingProgress, phiMaskingEnabled]);
  const genericMixpanelEvents = useGenericMixpanelTrack([MixpanelPropertiesContext.MemberContext]);
  return <>
      <HHStack data-testid={PHI_MASK_SWITCH_BUTTON_TEST_ID}>
        <HHSwitch size="small" hhVariant="variant-bypass" aria-label="phi-access" checked={phiMaskingEnabled} onClick={handlePHIMaskingChange} sx={styles.switchButtonStyles} />
        <HHTypography hhVariant="body1" fontWeight={700} layoutStyles={{
        textAlign: 'center'
      }}>
          {PHI_MASK_SWITCH_BUTTON_TEXT}
        </HHTypography>
      </HHStack>

      {/* PHI masking status dialog Box */}
      <HHDialog open={phiMaskingDialogOpen} PaperProps={{
      sx: styles.dialogPaperStyles
    }}>
        {phiMaskingDialogElement}
      </HHDialog>
    </>;
};