const MAX_FILE_SIZE_IN_MB = 100;
const MAX_FILE_UPLOAD_LIMIT = 10;
export const USER_FEEDBACK = {
  FEEDBACK_PERSIST_KEY: 'FEEDBACK_PERSIST',
  BUG_STRING: 'BUG',
  REQUEST_STRING: 'REQUEST',
  SUCCESS_ALERT_PREFIX: 'Thank you for your feedback! Our team will review your ticket (',
  SUCCESS_ALERT_SUFFIX: ') shortly',
  SUCCESS_TITLE: 'Feedback submitted',
  FAILURE_TITLE: 'Feedback not submitted',
  ERROR_TEXT_VALUE: 'Please fill the required field',
  FILE_SIZE_ERROR: `Please upload a file smaller than ${MAX_FILE_SIZE_IN_MB} MB`,
  FILE_LIMIT_ERROR: `Maximum ${MAX_FILE_UPLOAD_LIMIT} files can be uploaded`,
  DIALOG_TEXTS: {
    FROM_TITLE: 'Share Feedback',
    MEMBER_UUID_TITLE: 'Member UUID(s) impacted (optional)',
    MEMBER_UUID_PLACEHOLDER: 'Member UUID',
    BUG: {
      TITLE: 'Summarise the issue',
      TITLE_PLACEHOLDER: 'Provide a title for the bug.',
      DESCRIPTION: 'Describe the issue, including any steps to reproduce or additional context',
      DESCRIPTION_PLACEHOLDER: 'Describe what did you expect to happen'
    },
    REQUEST: {
      TITLE: 'Summarise the request',
      TITLE_PLACEHOLDER: 'Provide a brief title or summary of the request',
      DESCRIPTION: 'Details of the request',
      DESCRIPTION_PLACEHOLDER: 'Describe request in detail and why is it important'
    },
    TITLE_BUG_REQUEST_QUES: 'Is it a bug or product/design request?',
    ATTACHMENT_TEXT: 'Attachment (optional)'
  },
  MEMBER_ID_PREFIX: 'Member UUID(s): ',
  UPLOAD_FILES_TEXT: 'Upload files',
  SUBMIT_FORM_BUTTON_TEXT: 'Submit',
  SUBMIT_ANOTHER_BUTTON_TEXT: 'Submit Another',
  DISCLAIMER_TEXT: 'Avoid uploading anything containing PHI data',
  TICKET_LABEL: {
    BUG: 'feedback_bug',
    REQUEST: 'feedback_request'
  },
  JIRA_BASE_TICKET_URL: 'https://hingehealth.atlassian.net/browse/',
  TEST_IDS: {
    DELETE_BUTTON: 'delete-file-',
    FILE_INPUT: 'file-input',
    CLOSE_BUTTON: 'close-button'
  },
  MAX_NUMBER_OF_FILES_UPLOAD: MAX_FILE_UPLOAD_LIMIT,
  MAX_FILE_SIZE_IN_MB
};