import { UnreadMessagesWorkflowTypes } from '../../../../custom-types';
import { CoachWorkflowTypes } from '../../types/coach';
export const COACH_WORKFLOW_TYPES: Record<CoachWorkflowTypes | UnreadMessagesWorkflowTypes, string> = {
  [CoachWorkflowTypes.FirstActionPlan]: 'Create action plan',
  [CoachWorkflowTypes.UnreadMessages]: 'Unread Message',
  [CoachWorkflowTypes.MemberAlertReminder]: 'Alert Reminder',
  [CoachWorkflowTypes.ActionPlanReview]: 'Review and recalibrate',
  [CoachWorkflowTypes.FirstWeekGoal]: 'Review and recalibrate',
  [CoachWorkflowTypes.PregnancyStatusChange]: 'Pregnancy Status Change',
  [CoachWorkflowTypes.OfferVideoVisit]: 'Offer Video Visit',
  [CoachWorkflowTypes.MemberHealthLog]: 'Health log note',
  [CoachWorkflowTypes.MemberPainUptick]: 'Health log - pain uptick',
  [CoachWorkflowTypes.BalanceAssessment]: 'Health log',
  [CoachWorkflowTypes.MaternityMilestones]: 'Maternity Milestones',
  [CoachWorkflowTypes.MemberHealthLogNoResponse]: 'Health log - No Response',
  [CoachWorkflowTypes.UnreadMessagesNoResponse]: 'Unread Messages - No Response',
  [CoachWorkflowTypes.ActionPlanCheckIn]: 'Action plan check-in',
  [CoachWorkflowTypes.FirstWeekCheckIn]: 'First week check-in',
  [CoachWorkflowTypes.CoachCall]: 'Coach call',
  [CoachWorkflowTypes.EnsoWeek1]: 'Initial Enso Outreach',
  [CoachWorkflowTypes.ReEngager]: 'Re-engager',
  [CoachWorkflowTypes.CoachEscalation]: 'Coach escalation',
  [CoachWorkflowTypes.WeeklyEnsoReview]: 'Weekly Enso review',
  [UnreadMessagesWorkflowTypes.CareTeamMessage]: 'Care team message',
  [CoachWorkflowTypes.OutreachNeeded]: 'Outreach needed',
  [UnreadMessagesWorkflowTypes.InAppMessage]: '',
  [CoachWorkflowTypes.CoachWeeklyGoalExpiry]: 'Review Plan',
  [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal]: 'Member created plan',
  [CoachWorkflowTypes.CoachVideoVisit]: 'Video visit'
};