export const sxStyles = {
  errorOutlineRounded: {
    color: 'error',
    bgcolor: 'rgba(252, 225, 222, 1)',
    width: '40px',
    height: '40px',
    padding: '8px',
    borderRadius: '8px'
  },
  hhListItem: {
    width: '100%',
    bgcolor: 'rgba(249, 247, 242, 1)',
    borderRadius: '12px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px'
  },
  stack: {
    width: '100%',
    justifyContent: 'space-between'
  },
  hhDialogActions: {
    marginTop: '32px',
    padding: '0px',
    position: 'sticky',
    bottom: 0,
    background: 'linear-gradient(49.05deg, rgba(255, 255, 255, 0.7) 28.35%, rgba(255, 255, 255, 0) 65.09%)'
  },
  closeButton: {
    float: 'right',
    cursor: 'pointer'
  },
  hhDialogPaperProps: {
    width: '500px',
    borderRadius: '15px',
    position: 'absolute',
    bottom: 0,
    left: '40px',
    padding: '10px'
  },
  hhDialogContentText: {
    marginTop: '15px',
    marginBottom: '8px'
  },
  fileUploadErrorTypography: {
    color: 'rgba(207, 49, 43, 1)',
    fontSize: '12px',
    marginLeft: '8px'
  },
  phiDisclaimerBox: {
    width: '100%',
    bgcolor: 'rgba(207, 185, 145, 0.12)',
    borderRadius: '32px',
    padding: '4px 6px 4px 6px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px'
  },
  phiDisclaimerTypography: {
    color: 'rgba(103, 111, 109, 1)',
    marginTop: '2px!important'
  }
};
export const layoutStyles = {
  typography: {
    marginTop: '10px',
    marginBottom: '8px',
    textAlign: 'center'
  },
  fileUploadErrorTypography: {
    top: '8px',
    paddingTop: '8px'
  }
};