import { NAV_SPLIT_TREATMENTS } from '../../../constants/strings/split';
export const SPLIT_TREATMENTS = {
  UNREAD_MESSAGES_FILTER: 'unread-messages-filter',
  UNREAD_MESSAGE_ML: 'unread-message-ml',
  UPDATE_FROM_SSE_TRANSITIONS: 'update-from-sse-task-transitions',
  HEALTH_LOG_PAIN_UPTICKS: 'health-log-pain-upticks',
  HEALTH_LOG_NOTES: 'member-health-log',
  THREE_PANEL_LAYOUT: 'three-panel-layout',
  MEDIUM_TIER_RETENTION_WORKFLOWS: 'medium-tier-retention-workflows',
  ENCOUNTERS: 'coach-dashboard-encounters-filter',
  REENGAGER_WORKFLOW_MIGRATION: 'reengager-workflow-migration',
  ENSO_WEEK_1_WORKFLOWS: 'enso-week-1-workflows',
  SURGERY_TREND_WORKFLOW_MIGRATION: 'surgerytrend-workflow-migration',
  VIDEO_VISIT_FOLLOWUPS: 'video-visit-followups',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT,
  PT_POOLED_DASHBOARD: 'pt-pooled-dashboard',
  FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION: 'first-week-enso-check-in-workflow-migration',
  WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION: 'weekly-enso-review-workflow-migration',
  PAIN_UPTICK_WORKFLOWS_ASSIGNED_TO_PT: 'pain-upticks-assigned-to-pt',
  OUTREACH_NEEDED_WORKFLOW_MIGRATION: 'outreach-needed-workflow-migration',
  AQ_RED_FLAGS: 'aq-flags-workflow',
  PT_HLN_ML: 'pt-hln-ml',
  PT_UNREAD_MESSAGES_ML: 'pt-unread-message-ml',
  PT_MEMBER_ALERTS_REMINDERS: 'pt-member-alerts',
  PT_ACTION_PLAN_REVIEW: 'pt-action-plan-review-workflow',
  SETUP_ACTION_PLAN_WORKFLOW: 'setup-action-plan-workflow',
  COACH_WEEKLY_GOAL_EXPIRY: 'coach-weekly-goal-expiry-workflow',
  COACH_MEMBER_UPDATED_WEEKLY_GOAL: 'coach-member-updated-weekly-goal-workflow',
  INDICATION_UPDATE_WORKFLOW: 'indication-update-workflow',
  OS_MEMBER_ALERTS_REMINDERS: 'os-member-alerts',
  COACH_VIDEO_VISIT: 'coach-video-visit'
};
export const ON = 'on';
export const OFF = 'off';