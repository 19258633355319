import { HHLayoutTabContent, HHSnackbarProps } from '@hinge-health/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'jotai';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { Admin, CareTeamCoverageInfo, MemberAlert, User, UserPathway } from '../../../types';
import { ActionPlan } from '../components/care-plan/action-plan/models/action-plan';
import { EditDispatchedMessageData, PrimaryAssigneeDetails } from '../components/communication/iam/types/iam-types';
import { UserName } from '../components/profile/types';
import { EnrollmentStatus } from '../constants/strings/member';
import { MessageFeedbackResponse } from '../custom-types';
import { CareTeamQuery } from '../queries/care-team-coverage.graphql';
import { MemberInfo } from '../utils/member-information-helper';
export const actionPlansAtom = atom<ActionPlan[]>([]);
export const currentAdminAtom = atom<Partial<Admin>>(({} as Admin));
export const currentUserAtom = atom<Partial<User>>(({} as User));
export const currentUserPathwaysAtom = atom<Partial<UserPathway>[]>([]);
export const actionPlanBeingEditedIdAtom = atom<number | null>(null);
export const memberAlertsAtom = atom<MemberAlert[]>([]);
export const memberTagsListAtom = atom<MemberInfo[]>([]);
export const priorityMemberAlertAtom = atom<MemberAlert | null>(null);
export const joinAndSendAtom = atom<boolean>(false);
export const configuratorWriteAccessAtom = atom<boolean>(false);
export const datePickerAtom = atom<boolean>(false);
export const validDateTimeAtom = atom<boolean>(true);
export const selectedDateTimeAtom = atom<Dayjs>(dayjs());
export const editMessageDataAtom = atom<{
  messageId: string;
  body: string;
  scheduledFor: Dayjs;
  isEdit: boolean;
  args?: string;
}>({
  messageId: '',
  body: '',
  scheduledFor: dayjs(),
  isEdit: false,
  args: ''
});
export const editDispatchedMessageDataAtom = atom<EditDispatchedMessageData>({
  messageId: '',
  body: '',
  isEdit: false,
  attachments: []
});
export const customMessageListAtom = atom<StreamMessage<DefaultStreamChatGenerics>[] | undefined>([]);
export const customMessageListLoadingAtom = atom<boolean | null>(null);
export const editMessageAtom = atom<boolean>(false);
export const editDispatchedMessageAtom = atom<boolean>(false);
export const iamMessageInputErrorAtom = atom<string | null>(null);

// State to trigger snackbar for member 360 page
export const SNACKBAR_DEFAULT_PROPS: HHSnackbarProps = {
  open: false,
  message: '',
  autoHideDuration: 3000
};
export const member360SnackbarAtom = atom<HHSnackbarProps>(SNACKBAR_DEFAULT_PROPS);
export const aiMessageFeedbackAtom = atom<string | undefined>(undefined);
export const aiMessageFeedbackResponseAtom = atom<MessageFeedbackResponse | undefined>(undefined);
export const hasCareTeamChannelAtom = atom<boolean>(false);
export const isRecordPanelMinimisedAtom = atom<boolean>(false);
export const determineCoverageForUserAtom = atom<string | undefined>(undefined);
export const activeCareTeamCoverageMemberDetails = atom<CareTeamCoverageInfo[]>([]);
export const pimaryAssigneeDetails = atom<PrimaryAssigneeDetails>({
  name: '',
  role: '',
  coverageType: '',
  isActive: false
});
export const getStreamChannelsAtom = atom<Channel[] | undefined>(undefined);
export const memberBannerHeightAtom = atom<number>(0);
export const iamLoadingAtom = atom<boolean>(false);
export const currentUserNameAtom = atom<UserName>({
  firstNameLegal: '',
  lastName: '',
  firstNamePreferred: ''
});
export const refetchUserAtom = atom<boolean>(false);
export const refetchEnrollmentStatusAtom = atom<boolean>(false);
export const refetchUserNameAtom = atom<boolean>(false);
export const enrollmentStatusAtom = atom<EnrollmentStatus>(EnrollmentStatus.OPT_IN);
export const careTeamCoverageDataAtom = atom<CareTeamQuery>({
  careTeam: []
});
export const iamTabLabelAtom = atom<string>('IAM');
export const channelCreateLoadingAtom = atom<boolean>(false);
export const selectedTabAtom = atom<'IAM' | 'SMS' | null>(null);
export const iamChannelTabs = atom<HHLayoutTabContent[]>([]);
export const eventReceived = atom<boolean>(false);
export const refetchActiveMemberAlertsAtom = atom<boolean>(false);
export const onboardingDateAtom = atom<string | null | undefined>(null);
export const iamConversationLoadingAtom = atom<boolean>(false);