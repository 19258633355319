import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CoachOrOsWorkflowTypes, commonCoachCompletedStates, commonCoachExcludedStates, PtWorkflowTypes, QueueCategoryType } from '../custom-types';
import { SseEvents } from '../modules/dashboard/constants/events';
import { ON, SPLIT_TREATMENTS } from '../modules/dashboard/constants/splits';
import { ensoWeek1SplitFlag, healthLogNotesSplitFlag, unreadMessagesMlSplitFlag } from '../modules/dashboard/jotai/atoms';
import { commonPtCompletedStates, commonPtExcludedStates } from '../modules/dashboard/types/pt';
import { getEndOfTomorrowInBusinessDays } from '../modules/dashboard/utils/get-formatted-date';
import { coachOrOsWorkflowTypes } from '../modules/member-360/components/communication/interactions/utils/utils';
import { useShellContext } from '../modules/sidebar/contexts/shell.context';
import { currentAdminUuid } from '../state/queue-atoms';
import { CountWorkflowsFiltersInputDto, WorkflowSubtotals } from '../types';
import { OktaRole } from '../types/okta-roles';
import { getBulkWorkflowSseData, getWorkflowSseData } from '../utils/coach-sse';
import { useGetQueueCountsCommonHook } from './use-get-queue-counts-common-hook';
import { useGetQueueWorkflowTypes } from './use-get-queue-workflow-types';
import { useSse } from './use-sse';
const getCompletedStates = (roles: string[]): string[] => roles.includes(OktaRole.PhysicalTherapist) ? commonPtCompletedStates : commonCoachCompletedStates;
const getExcludedStates = (roles: string[]): string[] => roles.includes(OktaRole.PhysicalTherapist) ? commonPtExcludedStates : commonCoachExcludedStates;
export const useGetIncompleteQueueCounts = (queueCategoryType: QueueCategoryType | null = null, useStartAndEndDate?: boolean): {
  totalCount: string;
  subTotalCounts?: WorkflowSubtotals[] | null;
  errorInCountFetch: boolean;
  loading: boolean;
} => {
  const [totalIncompleteCount, setTotalIncompleteCount] = useState('');
  const workflowTypesToCount = useGetQueueWorkflowTypes();
  const {
    adminProfile
  } = useShellContext();
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const roles = hingeHealthAuthState?.accessToken?.claims.roles;
  const isCoach = useMemo(() => roles?.includes(OktaRole.Coach), [roles]);
  const isOS = useMemo(() => roles?.includes(OktaRole.OnboardingSpecialist), [roles]);
  const isPt = useMemo(() => roles?.includes(OktaRole.PhysicalTherapist), [roles]);
  const isCoachOrOs = isCoach || isOS;
  const dueTaskEndDate = isCoachOrOs ? getEndOfTomorrowInBusinessDays() : undefined;
  const useCoverage = isCoachOrOs;
  const completedStates = getCompletedStates(hingeHealthAuthState?.accessToken?.claims.roles ?? '');
  const excludeStates = getExcludedStates(hingeHealthAuthState?.accessToken?.claims.roles ?? '');
  const {
    totalCount,
    subTotalCounts,
    errorInCountFetch,
    loading,
    refetch,
    subscribedSseData,
    workflowsQueueSplitOn,
    setCountWorkflowFilter,
    updateWorkflowCounts
  } = useGetQueueCountsCommonHook(workflowTypesToCount, {
    countWorkflowsFilters: {
      dueTaskEndDate,
      excludeStates,
      workflowTypes: workflowTypesToCount,
      completedStates,
      useCoverage
    }
  }, queueCategoryType, useStartAndEndDate);
  const [adminUuid] = useAtom(currentAdminUuid);
  const splitClient = useClient();
  const [updateHealthLogNotesSplitFlag, setUpdateHealthLogNotesSplitFlag] = useAtom(healthLogNotesSplitFlag);
  const [unreadMessagesMlSplitFlagValue, setUnreadMessagesMlSplitFlagValue] = useAtom(unreadMessagesMlSplitFlag);
  const [updateEnsoWeek1SplitFlag, setUpdateEnsoWeek1SplitFlag] = useAtom(ensoWeek1SplitFlag);
  setUpdateHealthLogNotesSplitFlag(splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON);
  setUnreadMessagesMlSplitFlagValue(splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGE_ML, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON);
  setUpdateEnsoWeek1SplitFlag(splitClient?.getTreatment(SPLIT_TREATMENTS.ENSO_WEEK_1_WORKFLOWS, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON);
  let bulkSseEvents: SseEvents[] = [];
  const lowPriorityNotesSplitFlag = updateHealthLogNotesSplitFlag || unreadMessagesMlSplitFlagValue || updateEnsoWeek1SplitFlag;
  if (lowPriorityNotesSplitFlag) {
    bulkSseEvents = [SseEvents.BulkWorkflowTransitionCompleted, SseEvents.BulkWorkflowTransitionFailed];
  }
  const bulkMarkSseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, bulkSseEvents, {
    replayLastEvent: true
  });
  const refetchCounts = useCallback(async (): Promise<void> => {
    const countWorkflowsFilters: CountWorkflowsFiltersInputDto = {
      adminUuids: [adminUuid],
      workflowTypes: workflowTypesToCount,
      dueTaskEndDate,
      excludeStates,
      completedStates,
      useCoverage
    };
    if (setCountWorkflowFilter) {
      setCountWorkflowFilter(countWorkflowsFilters);
    }
    if (refetch) {
      await refetch({
        countWorkflowsFilters
      });
    }
  }, [adminUuid, completedStates, dueTaskEndDate, excludeStates, refetch, setCountWorkflowFilter, useCoverage, workflowTypesToCount]);
  useEffect(() => {
    const refetchQueueCounts = async (): Promise<void> => {
      if (bulkMarkSseData && refetch && lowPriorityNotesSplitFlag) {
        const workflow = getBulkWorkflowSseData(bulkMarkSseData);
        if (workflowTypesToCount.includes(workflow.workflowType)) {
          refetchCounts();
        }
      }
    };
    if (workflowsQueueSplitOn) {
      refetchQueueCounts();
    }
  }, [bulkMarkSseData, lowPriorityNotesSplitFlag, refetch, refetchCounts, workflowTypesToCount, workflowsQueueSplitOn]);
  useEffect(() => {
    if (subscribedSseData) {
      updateWorkflowCounts({
        subscribedSseData
      });
      const workflow_0 = getWorkflowSseData(subscribedSseData);
      if (workflow_0.type === SseEvents.WorkflowCreated) {
        // According to the current requirements, Coach dashboard should have only the workflows which are due in the next business day
        // whereas PT dashboard does not have any such restriction.
        // Consequently, the incomplete count is incremented by 1 for PTs upon the creation of a workflow regardless of the due date.
        // For coaches, the count is to be incremented only if the workflow is due within one business day.
        // The due date information is not available at the moment of workflow creation; It is only added during the initial auto-transition of workflow.
        // This limitation makes it impossible to ascertain whether a newly created workflow for a coach is due within one business day on creation.
        // To address this issue and ensure the accuracy of the count for coaches, the refetchCounts() function is called for all workflow creation events for coaches.
        if (isCoachOrOs && coachOrOsWorkflowTypes.includes(((workflow_0.serviceName as unknown) as CoachOrOsWorkflowTypes))) {
          refetchCounts();
        }
        if (
        /** This condition is required to check if the workflow is assigned to PT.
          Since a PT can create workflows for coaches and receive a SSE for it,
          the count should not be incremented for those workflows. */
        isPt && Object.values(PtWorkflowTypes).includes(((workflow_0.serviceName as unknown) as PtWorkflowTypes))) {
          setTotalIncompleteCount(`${+totalIncompleteCount + 1}`);
        }
      } else if (workflow_0.type === SseEvents.WorkflowTransitioned && [...commonCoachCompletedStates, ...commonPtCompletedStates].includes(workflow_0.stateName) && +totalIncompleteCount) {
        setTotalIncompleteCount(`${+totalIncompleteCount - 1}`);
      } else if (workflow_0.type === SseEvents.TaskUpdated && isCoachOrOs && coachOrOsWorkflowTypes.includes(((workflow_0.serviceName as unknown) as CoachOrOsWorkflowTypes))) {
        // This is to update the count when a task is updated for a coach
        // This is required because the latestTaskDate of workflow is updated when a task is updated
        // and the updated latestTaskDate can possibly fall within the next business day
        refetchCounts();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedSseData]);
  useEffect(() => {
    setTotalIncompleteCount(totalCount);
  }, [totalCount]);
  return {
    totalCount: totalIncompleteCount,
    subTotalCounts,
    errorInCountFetch,
    loading
  };
};