import { atom } from 'jotai';
import { WorkflowPayload } from '../types';
import { CreateCareCoordinationWorkflowFormSelection, CreateCareCoordinationWorkflowState } from '../workflows-panel/create-workflow-forms/types/types';
export const currentWorkflowAtom = atom<{
  currentWorkflow: WorkflowPayload | null;
  title: string;
  subtitle?: string;
  loading: boolean;
  error: boolean;
}>({
  currentWorkflow: null,
  title: '',
  loading: false,
  error: false
});
export const createCareCoordinationWorkflowAtom = atom<CreateCareCoordinationWorkflowState>({
  loading: false,
  error: false,
  success: false
});
export const workflowAssigneeUuidAtom = atom<string | undefined>(undefined);
export const openCreateWorkflowFormAtom = atom<CreateCareCoordinationWorkflowFormSelection>({
  openCreateWorkflowType: undefined,
  isSelectedFromMemberRecord: false
});
export const ET_MODIFICATION_FORM_DEFAULT_STATE = {
  context: '',
  bodyPart: '',
  side: '',
  description: ''
};
export const CLINICAL_ESCALATION_FORM_DEFAULT_STATE = {
  context: '',
  reason: 'out_of_scope',
  description: ''
};
export const COACH_ESCALATION_FORM_DEFAULT_STATE = {
  context: '',
  reason: 'coach_expertise',
  description: ''
};
export const MEDICAL_EMERGENCY_FORM_DEFAULT_STATE = {
  context: '',
  level: 1,
  description: ''
};
export const etModificationFormStateAtom = atom(ET_MODIFICATION_FORM_DEFAULT_STATE);
export const clinicalEscalationFormStateAtom = atom(CLINICAL_ESCALATION_FORM_DEFAULT_STATE);
export const coachEscalationFormStateAtom = atom(COACH_ESCALATION_FORM_DEFAULT_STATE);
export const medicalEmergencyFormStateAtom = atom(MEDICAL_EMERGENCY_FORM_DEFAULT_STATE);
export const minimizedMemberPagePanelAtom = atom('');
export const isThreePanelInLayoutAtom = atom(false);
export const createnewCareCoordinationWorkflowAtom = atom(false);